<style>
.el-icon-info{color:#999; font-size:32px; margin-right:5px;}
.line{display:flex; flex-wrap: nowrap;}
.role{ max-width:150px; }
.subject{font-size:12px; color:#999;}
.value{font-size:12px; color:#666; }
.keyword{width:200px;}
.red{color:red;}
.gray{color:#aaa;}
.block td{background-color:#e6e6e6 !important}
.waitpass td{background-color:#fffaeb !important}
.cell .el-icon-circle-check{font-size: 20px; color:#67C23A;}
.cell .el-icon-time{font-size: 20px; color:#F56C6C;}
.edit_dialog{min-height: 300px; height:auto; margin:15vh auto !important;}
.edit_dialog .el-row:nth-of-type(n+2){margin-top:20px;}
.edit_dialog .tips{font-size:12px; color:#666;}

.edit_dialog .subject{font-size:15px; color:#666; text-align: right}
.el-autocomplete-suggestion li{padding:0 10px; display:flex; flex-wrap: nowrap;}
.el-autocomplete__popper .idnumber{flex:0 0 auto; color:#88001b; font-size:15px;}
.el-autocomplete__popper .name{flex:1 1 auto; text-align: center;  color:#001088; font-size:15px;}
.el-autocomplete__popper .phone{flex:0 0 auto; color:#88001b; font-size:15px;}

.edit_dialog .weight{font-size:18px; color:#333; text-align: right; font-family: calibri}
.map_dialog{height:90%; margin:5vh auto !important;}
.map_dialog .el-dialog__body{padding:0;}
.map_dialog .address{font-size:22px; font-weight: bold; color:#666; padding-right:30px;}
#map{height:100%;}
</style>
<template>
    <div class="component">
        <!-- 编辑框 -->
        <el-dialog width="400px" custom-class="edit_dialog" :title="data_for_edit.id > 0 ? '编辑业主' : '添加业主'" v-model="show_edit" :close-on-click-modal="false">
            <el-row :gutter="10">
                <el-col :span="5" class="subject">身份证号</el-col>
                <el-col :span="19">
                    <!-- <el-autocomplete style="width: 100%" v-model="data_for_edit.idnumber" :fetch-suggestions="search_idnumber" :debounce="500" placeholder="输入身份证可搜索已有用户" @select="select_user" size="small" :trigger-on-focus="false" :disabled="autocomplete_disabled">
                        <template #append v-if="autocomplete_disabled"><el-button icon="el-icon-circle-close" @click="cleanuser"></el-button></template>
                        <template #default="{ item }">
                                <span class="idnumber">{{ item.idnumber.substr(0,6)+"**"+item.idnumber.substr(-4) }}</span>
                                <span class="name">{{ item.truename }}</span>
                                <span class="phone">{{ item.phone }}</span>
                        </template>
                    </el-autocomplete> -->
                    <el-input v-model="data_for_edit.idnumber" placeholder="请输入身份证" size="small"></el-input>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :span="5" class="subject">手机号码</el-col>
                <el-col :span="19">
                    <!-- <el-autocomplete style="width: 100%" v-model="data_for_edit.phone" :fetch-suggestions="search_phone" :debounce="500" placeholder="输入手机号可搜索已有用户" @select="select_user"  @clear="cleanuser" size="small" :trigger-on-focus="false" :disabled="autocomplete_disabled">
                        <template #append v-if="autocomplete_disabled"><el-button icon="el-icon-circle-close" @click="cleanuser"></el-button></template>
                        <template #default="{ item }">
                                <span class="idnumber">{{ item.idnumber.substr(0,6)+"**"+item.idnumber.substr(-4) }}</span>
                                <span class="name">{{ item.truename }}</span>
                                <span class="phone">{{ item.phone }}</span>
                        </template>
                    </el-autocomplete> -->
                    <el-input v-model="data_for_edit.phone" placeholder="请输入手机号" size="small"></el-input>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :span="5" class="subject">真实姓名</el-col>
                <el-col :span="19">
                    <!-- <el-autocomplete style="width: 100%" v-model="data_for_edit.truename" :fetch-suggestions="search_name" :debounce="500" placeholder="输入姓名可搜索已有用户" @select="select_user" size="small" clearable :trigger-on-focus="false" :disabled="autocomplete_disabled">
                        <template #append v-if="autocomplete_disabled"><el-button icon="el-icon-circle-close" @click="cleanuser"></el-button></template>
                        <template #default="{ item }">
                                <span class="idnumber">{{ item.idnumber.substr(0,6)+"**"+item.idnumber.substr(-4) }}</span>
                                <span class="name">{{ item.truename }}</span>
                                <span class="phone">{{ item.phone }}</span>
                        </template>
                    </el-autocomplete> -->
                    <el-input v-model="data_for_edit.truename" placeholder="请输入姓名" size="small"></el-input>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :span="5" class="subject">登录密码</el-col>
                <el-col :span="19">
                    <el-input v-model="data_for_edit.passwd" :placeholder="data_for_edit.id>0?'不修改请留空':'密码至少6位'" autocomplete="off"  size="small"></el-input>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :span="5" class="subject">拥有房屋</el-col>
                <el-col :span="19">
                    <el-cascader clearable v-model="data_for_edit.bufh" :props="pop_props" style="width:100%" ref="bufh" @change="bufh_change" :key="cascader_key" size="small"></el-cascader>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :span="5" class="subject">住宅面积</el-col>
                <el-col :span="19">
                    <el-input v-model="data_for_edit.house" placeholder="填写数字，平米" autocomplete="off"  size="small"></el-input>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :span="5" class="subject">商业面积</el-col>
                <el-col :span="19">
                    <el-input v-model="data_for_edit.shop" placeholder="填写数字，平米" autocomplete="off"  size="small"></el-input>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :span="5" class="subject">车位面积</el-col>
                <el-col :span="19">
                    <el-input v-model="data_for_edit.parking" placeholder="填写数字，平米" autocomplete="off"  size="small"></el-input>
                </el-col>
            </el-row>
            <template #footer>
                <el-button type="primary" @click="click_edit_done">保 存</el-button>
                <el-button @click="show_edit = false">关 闭</el-button>
            </template>
        </el-dialog>
        <!-- 编辑框结束 -->

        

        <div class="title-panel">
            <el-row>
                <el-col :span="7">
                    <i class="el-icon-info"></i>
                    <div class="inline"><div class="label">业主管理</div><div class="descript">管理小区业主信息。支持excel导入数据。</div></div>
                </el-col>
                <el-col :span="17" class="btn-col">
                    <el-button type="success" plain icon="el-icon-plus" @click="click_add">单个添加</el-button>
                    <el-button type="danger" plain icon="el-icon-upload2" @click="click_import">批量导入</el-button>
                    <el-button type="warning" plain icon="el-icon-download" @click="click_template">下载导入模板</el-button>
                    <el-button type="info" plain icon="el-icon-document" @click="click_export">批量导出</el-button>
                    <el-button type="primary" plain icon="el-icon-refresh" @click="load_list">刷新数据</el-button>
                </el-col>
            </el-row>
        </div>
        <!-- 筛选条 -->
        <el-row style="display:flex; align-items: center; background: #f9f9f9; padding:5px 5px;">
            <el-select style="width:120px;" clearable v-model="pass" placeholder="过审状态" size="small">
                <el-option label="已过" value="1"></el-option>
                <el-option label="待审" value="0"></el-option>
            </el-select>
            <div>&nbsp;</div>
            <el-select style="width:120px;" clearable v-model="block" placeholder="停用状态" size="small">
                <el-option label="正常" value="0"></el-option>
                <el-option label="停用" value="1"></el-option>
            </el-select>
            <div>&nbsp;</div>
            <el-select style="width:120px;" clearable v-model="active" placeholder="激活状态" size="small">
                <el-option label="已激活" value="1"></el-option>
                <el-option label="未激活" value="2"></el-option>
            </el-select>
            <div>&nbsp;</div>
            <el-cascader clearable v-model="bufh" :props="panel_props" style="width:250px" ref="bufh1" @change="$refs.bufh1.popperVisible=false" size="small"></el-cascader>
            <div>&nbsp;</div>
            <el-input style="width:250px" class="keyword" size="small" v-model="keyword" placeholder="搜索 姓名、手机号、身份证" clearable></el-input> 
            <div style="flex-grow:1">&nbsp;</div>
            <el-pagination background layout="total, prev, pager, next" :page-size="size" :current-page="page" :total="total" @current-change="page_change"></el-pagination>
        </el-row>
        <!-- 筛选条结束 -->
        <div class="table-box" id="table-box">
            <el-table :max-height="table_height" :data="list" :stripe="true" border :row-class-name="tableRowClassName">
                <el-table-column prop="lastactive" label="最近活动时间" align="center" width="180" :formatter="format_timestamp"></el-table-column>
                <el-table-column prop="truename" label="姓名" align="center" width="120"></el-table-column>
                <el-table-column prop="phone" label="手机号" align="center" width="120"></el-table-column>
                <el-table-column prop="idnumber" label="身份证号" align="center" width="120" :formatter="(row,col,val)=>{return val.substr(0,6)+'**'+val.substr(-4);}"></el-table-column>
                <el-table-column prop="bufh_name" label="拥有房屋" align="center" width="300" :formatter="function(row,col,val){return val.replace(/,/g,' / ');}"></el-table-column>
                <el-table-column prop="house" label="住宅" align="center" width="100" :formatter="(row,col,val)=>{return val ? val+'m²' : '-'}"></el-table-column>
                <el-table-column prop="shop" label="商业" align="center" width="100" :formatter="(row,col,val)=>{return val ? val+'m²' : '-'}"></el-table-column>
                <el-table-column prop="parking" label="车位" align="center" width="100" :formatter="(row,col,val)=>{return val ? val+'m²' : '-'}"></el-table-column>
                <el-table-column prop="pass" label="过审" width="100" align="center" :formatter="resolve_pass"></el-table-column>
                <el-table-column fixed="right" min-width="120" label="操作">
                    <template #default="scope">
                        <template v-if="scope.row.pass==1">
                            <el-button v-if="scope.row.block==0" @click="click_block(scope.$index)" type="text" size="small">停用</el-button>
                            <el-button v-else @click="click_block(scope.$index)" type="text" size="small">启用</el-button>
                        </template>
                        <el-button v-else @click="click_pass(scope.$index)" type="text" size="small">通过</el-button>
                        <el-button @click="click_edit(scope.$index)" type="text" size="small">编辑</el-button>
                        <el-button @click="click_del(scope.$index)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>      
        </div>
    </div>
</template>
<script>
import {inject} from 'vue'
import format_timestamp from "../plugins/utils.js"
//import BufhCascader from "../components/BufhCascader.vue"
import XLSX from 'xlsx'
import FileSaver from 'file-saver'
export default {
    //components:{ BufhCascader },
    setup:function(){
        const gd = inject('gd');
        return {gd};
    },//setup
    data:function(){
        return {
            cascader_key:0,
            page:1,
            size:10,
            total:0,
            block:'',
            active:'',
            pass:'',
            bufh:'',
            keyword:'',
            keyword_delay_handle:null,
            list:[],
            show_edit:false,
            data_for_edit:{},
            autocomplete_disabled:false,
            table_height:800,
            pop_props: {
                label:'name',
                value:'id',
                lazy: true,
                expandTrigger:'hover',
                checkStrictly:false,
                lazyLoad:(node, resolve)=>{
                    let post = {};
                    if(node.value && !isNaN(node.value)){ post.id = node.value;}
                    this.axios.post("/api/house/list",post).then(ret=>{
                        if(!ret.ok){ this.$message.error(ret.msg); return;}
                        ret.data.map(item=>{ item.leaf = item.hasChildren == 0;})
                        resolve(ret.data);
                    });
                },//lazyLoad
            },
            panel_props: {
                label:'name',
                value:'id',
                lazy: true,
                checkStrictly:true,
                expandTrigger:'hover',
                lazyLoad:(node, resolve)=>{
                    let post = {};
                    if(node.value && !isNaN(node.value)){ post.id = node.value;}
                    this.axios.post("/api/house/list",post).then(ret=>{
                        if(!ret.ok){ this.$message.error(ret.msg); return;}
                        ret.data.map(item=>{ item.leaf = item.hasChildren == 0;})
                        resolve(ret.data);
                    });
                },//lazyLoad
            }
        }
    },//data end
  mounted:function(){
    this.$nextTick(()=>{ this.table_height = document.getElementById('table-box').offsetHeight -12; });
    this.init();
  },
  watch:{
    'gd.me.eid':function(){
        this.load_list();
    },
    page:function(){ this.load_list(); },//page
    block:function(){ this.page=1; this.load_list(); },//block
    pass:function(){ this.page=1; this.load_list(); },//pass
    active:function(){ this.page=1; this.load_list(); },//active
    bufh:function(){ this.page=1; this.load_list(); },//bufh
    keyword:function(){
        clearTimeout(this.keyword_delay_handle);
        this.keyword_delay_handle = setTimeout(()=>{
            this.page=1; this.load_list();
        }, 800);
        
    },//keyword
  },//watch
  methods:{
    init: function(){
        this.load_list();
    },//init
    
    page_change:function(page){ this.page = page; },//page_change
    bufh_change:function(){
        this.$refs.bufh.popperVisible = false;
    },//bufh_change
    load_list:function(){
        //console.log(this.date);
        let para = {page:this.page, size:this.size, block:this.block, pass:this.pass, active:this.active, bufh:this.bufh, key:this.keyword};
        this.axios.post("/api/user/resident",para).then(ret=>{
            if(!ret.ok){this.$message.error(ret.msg); return;}
            ret.data.list.map(item=>{ item.type += ''; });
            this.list = ret.data.list;
            this.total = ret.data.total;
        });
    },//load_list
    format_timestamp:function(row,col,val){
        return format_timestamp(val, true);
    },//
    resolve_pass:function(row){
        if(row.pass == 1){
            return <i class="el-icon-circle-check" />
        }else{
            return <i class="el-icon-time" />
        }
    },//resolve_pass
    tableRowClassName:function({row}){
    	if(row.pass == 0){return 'waitpass'; }
        if(row.block == 1){return "block"; }
    	return '';
    },//tableRowClassName
    click_block:function(idx){
        this.axios.post("/api/user/resident_block",{id:this.list[idx].id}).then(ret=>{
            if(!ret.ok){ this.$message.error(ret.msg); return;}
            this.$message.success(ret.msg);
            this.list[idx].block = 1 - this.list[idx].block;
        });
    },//click_block
    click_del:function(idx){
        this.$confirm('将从本小区删除该业主（不会删除账号）, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.axios.post("/api/user/resident_del",{id:this.list[idx].id}).then(ret=>{
                if(!ret.ok){ this.$message.error(ret.msg); return;}
                this.$message.success(ret.msg);
                this.load_list();
            });
        }).catch((e) => { console.error(e); }); 
    },//click_block
    click_pass:function(idx){
        this.axios.post("/api/user/resident_pass",{id:this.list[idx].id}).then(ret=>{
            if(!ret.ok){ this.$message.error(ret.msg); return;}
            this.$message.success(ret.msg);
            this.list[idx].pass = 1;
        });
    },//click_block
    click_add:function(){//单条添加
    	this.data_for_edit = {
            id:0,
            uid:0,
            idnumber:'',
            phone:'',
            truename:'',
            passwd:'',
            bufh:[],
            house:'',
            shop:'',
            parking:''
        };
        this.autocomplete_disabled = false;
        this.show_edit = true;
        //++this.cascader_key;
    },//click_add
    click_edit:function(idx){//单条编辑
        this.data_for_edit = JSON.parse(JSON.stringify(this.list[idx]));
        this.autocomplete_disabled = true;
        this.data_for_edit.passwd = '';
        this.show_edit = true;
    },//click_add
    /*--------编辑框功能-------*/
    search_idnumber:function(key, cb){
        if(key.length < 6){ cb([]); return;}
        this.axios.post("/api/user/search",{idnumber:key}).then(ret=>{
            if(!ret.ok){return;}
            cb(ret.data);
        });
    },//search_idnumber
    search_phone:function(key, cb){
        if(key.length < 6){ cb([]); return;}
        this.axios.post("/api/user/search",{phone:key}).then(ret=>{
            if(!ret.ok){return;}
            cb(ret.data);
        });
    },//search_phone
    search_name:function(key, cb){
        if(key.length < 1){ cb([]); return;}
        this.axios.post("/api/user/search",{truename:key}).then(ret=>{
            if(!ret.ok){return;}
            cb(ret.data);
        });
    },//search_name
    select_user:function(user){
        if(!user){return;}
        //this.data_for_edit.id = user.id;
        this.data_for_edit.uid = user.id;
        this.data_for_edit.idnumber = user.idnumber;
        this.data_for_edit.truename = user.truename;
        this.data_for_edit.phone = user.phone;
        this.autocomplete_disabled = true;
    },//select_user
    cleanuser:function(){
        this.data_for_edit.uid = 0;
        this.data_for_edit.idnumber = '';
        this.data_for_edit.truename = '';
        this.data_for_edit.phone = '';
        this.autocomplete_disabled = false;
    },//cleanuser
    click_edit_done:function(){
        // if(this.autocomplete_disabled){
        //     if(isNaN(this.data_for_edit.uid) || this.data_for_edit.uid<1){ this.$message.error("请选择有效的用户资料"); return; }
        // }else{
        //     if(this.data_for_edit.idnumber.length != 18){ this.$message.error("请输入18位身份证号码"); return;}
        //     if(this.data_for_edit.truename.length < 2){ this.$message.error("请输入真实姓名，最少2字"); return;}
        //     if(this.data_for_edit.phone.length != 11){ this.$message.error("请输入11位手机号码"); return;}
        // }
        if(this.data_for_edit.idnumber.length != 18){ this.$message.error("请输入18位身份证号码"); return;}
        if(this.data_for_edit.truename.length < 2){ this.$message.error("请输入真实姓名，最少2字"); return;}
        if(this.data_for_edit.phone.length != 11){ this.$message.error("请输入11位手机号码"); return;}

        if(this.data_for_edit.id == 0 && this.data_for_edit.passwd.length < 6){
            return this.$message.error("密码至少6位");
        }

        if(this.data_for_edit.passwd != ''){
            if(this.data_for_edit.passwd.length < 6){ return this.$message.error("密码至少6位");}
        }

        if(this.data_for_edit.bufh.length < 1){ this.$message.error("请选择业主持有房屋");}

        this.axios.post("/api/User/resident_edit",this.data_for_edit).then(ret=>{
            if(!ret.ok){ this.$message.error(ret.msg); return;}
            this.autocomplete_disabled = false;
            this.$message.success(ret.msg);
            this.show_edit = false;
            this.load_list();
        });
    },//click_edit_done
    //---------------批量导入------------------
    click_template:function(){
        window.open(this.gd.base_url+"/api/user/template?token="+this.gd.me.token+"&eid="+this.gd.me.eid);
    },//click_template
    remove_input:function(input){
        if(document.body.contains(input)){
            document.body.removeChild(input);
        } 
    },//remove_input
    click_import:function(){
        let input = document.createElement("INPUT");
        input.type = "file";
        input.addEventListener("change", e=>{
            if(e.srcElement.files.length != 1){ this.remove_input(input); return;}
            let f = e.srcElement.files[0];
            if(f.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
                this.$message.error("请上传excel文件（后缀名：xlsx");
                this.remove_input(input);
                return;
            }
            if(f.size < 100){
                this.$message.error("请上传有内容的文件（体积>0.1KB");
                this.remove_input(input);
                return;
            }
            let reader = new FileReader();
            reader.onload = (e)=>{
                let data = e.target.result;
                let wb = XLSX.read(data, {type: 'binary'});
                wb.SheetNames.map(name=>{this.process_sheet(wb.Sheets[name])});
            }
            this.loading = this.$loading({text:'处理中...'});
            setTimeout(()=>{//为了让loading显示出来
                this.remove_input(input);
                reader.readAsBinaryString(f);
                
            }, 20);
            //console.log(f.type);
        });
        document.body.append(input);
        input.click();
    },//click_import
    process_sheet:function(sheet){
        if(!sheet){this.loading.close(); this.$message.error("解析工作簿失败1"); return;}
        console.log(sheet['!ref']);
        let res = /:G(\d+)$/ig.exec(sheet['!ref']);
        //console.log(sheet);
        if(!res || res.length !=2){this.loading.close(); this.$message.error("解析工作簿失败2"); return;}
        let max_line_no = parseInt(res[1]);
        let data = [];
        let regExp = /[/]/g;
        //console.log(sheet);
        for(let i=1; i<=max_line_no; i++){
            
            if(!sheet['B'+i] || sheet['B'+i].w == ''){ continue; }
            if(sheet['B'+i].w == '姓名'){ continue; }
            if(!sheet['C'+i] || sheet['C'+i].w == ''){ this.$message.error("第"+i+"行手机号为空"); return; }
            let row = {
                b:sheet['A'+i].w,//户号
                n:sheet['B'+i].w,//姓名
                p:sheet['C'+i].w,//手机号
                i:sheet['D'+i] ? sheet['D'+i].w : '',//身份证
                h:sheet['E'+i] ? sheet['E'+i].w : '',//住宅
                s:sheet['F'+i] ? sheet['F'+i].w : '',//商业
                k:sheet['G'+i] ? sheet['G'+i].w : '',//车位
            }
            if(row.n.length < 2 || row.n.length > 30){ this.$message.error("第 "+i+" 行姓名要求在2-30字"); this.loading.close(); return;}
            if(row.p.length != 11 || isNaN(row.p) || row.p.substr(0,1) != '1'){ this.$message.error("第 "+i+" 行手机号不正确:"+row.p.length); this.loading.close(); return;}
            //if(row.i.length>0 && (row.i.length != 18 || (isNaN(row.i) && (isNaN(row.i.substr(0,17) && row.i.substr(17) == 'X'))))){ this.$message.error("第 "+i+" 行身份证号不正确"); this.loading.close(); return;}
            if(row.b == '' || !row.b.match(regExp)){ this.$message.error("第 "+i+" 行户号不能为空，且应该详尽到户"); this.loading.close(); return;}
            data.push(row);
        }
        if(data.length < 1){ this.$message.error("未找到有效数据行"); this.loading.close(); return; }
        this.loading.close();
        this.loading = this.$loading({text:'上传中...'});
        setTimeout(()=>{//为了让loadding显示出来
            this.axios.post("/api/user/resident_import",{data:data}).then(ret=>{
                this.loading.close();
                if(!ret.ok){ this.$message.error(ret.msg); return;}
                this.$message.success(ret.msg+"，共导入 "+ret.data.counter+" 条");
                this.load_list();
            });
        },20);
    },//process_sheet
    // ---------------------------------  批量导出 ----------------------------------
    click_export:function(){//点击顶上批量导出
        this.axios.post('/api/user/resident_export').then(ret=>{
                if(!ret.ok){ this.$message.error(ret.msg); return;}
                ret.data.list.map(item=>{
                    item.lastactive = item.lastactive > 0 ? format_timestamp(item.lastactive, true) : '-';
                });
                let header_sort = ["lastactive","truename","phone","idnumber","bufh_name","house","shop","parking"];
                ret.data.list.unshift({"lastactive":"最近活动时间","truename":"姓名","phone":"电话","idnumber":"身份证","bufh_name":"房屋户号","house":"住宅面积","shop":"商铺面积","parking":"车位面积"});
                let sheet = XLSX.utils.json_to_sheet(ret.data.list, {header:header_sort, skipHeader:true});
                let wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, sheet, 'sheet1');
                let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: false, type: 'binary'});
                FileSaver.saveAs(new Blob([this.s2ab(wbout)], {type: "application/octet-stream"}), ret.data.name+"业主导出.xlsx")
            });
        },//click_export
        s2ab:function(s) {
          if (typeof ArrayBuffer !== 'undefined') {
            let buf = new ArrayBuffer(s.length)
            let view = new Uint8Array(buf)
            for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF
            return buf
          } else {
            let buf = new Array(s.length)
            for (let i = 0; i !== s.length; ++i) buf[i] = s.charCodeAt(i) & 0xFF
            return buf
          }
        },//s2ab
  },//methods end
};
</script>